import { Controller } from "stimulus"
import { hljsHighlight } from "packs/highlight"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    if (this.highlighted !== true) {
      hljsHighlight(this.element);
      this.highlighted = true;
    };
  }
}
