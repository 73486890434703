import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { hljsHighlight } from "packs/highlight"

export default class extends Controller {
  static targets = [ "content", "display" ]

  update(event) {
    let content = this.contentTarget.value
    let formData = new FormData();

    formData.append('post[content]', content)

    Rails.ajax({
      type: "POST",
      url: "/posts/preview",
      dataType: "json",
      data: formData,
      success: (response) => {
	this.displayTarget.innerHTML = response.content
	hljsHighlight(this.displayTarget)
      },
      error: (response) => {
	console.log(response)
	this.displayTarget.innerText = "(An error occured)"
      }
    })
  }
}
