import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "primaryView", "secondaryView" ]

  connect() {
    this.showPrimaryView();
    // this.showSecondaryView();
  }

  showPrimaryView() {
    this.primaryViewTarget.classList.remove("d-none");
    this.secondaryViewTarget.classList.add("d-none");
  }

  showSecondaryView() {
    this.primaryViewTarget.classList.add("d-none");
    this.secondaryViewTarget.classList.remove("d-none");
  }
}
