import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "sidebar" ]

  toggleSidebarVisibility() {
    const sidebar = this.sidebarTarget;

    sidebar.classList.toggle("d-none")
  }
}
