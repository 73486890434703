import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  copy() {
    // let activeElement = window.document.activeElement;
    // this.inputTarget.select();
    // document.execCommand("copy");
    // activeElement.focus(); // TODO fix this, it's not working.
  }

  copyTextToClipboard(text) {
    let textArea = document.createElement("textarea");

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    onError = (error) => {
      console.log('Error: failed to copy text to clipboard');
      console.log(err);
    }

    try {
      if (!document.execCommand('copy')) {
	onError("document.execCommand failed");
      }
    } catch (err) {
      onError("document.execCommand failed");
    }

    document.body.removeChild(textArea);
  }
}
