import { Controller } from "stimulus"
import copyTextToClipboard from "lib/copy_text_to_clipboard"

import { hljs } from 'packs/highlight'

export default class extends Controller {
  static targets = [ "treeViewer", "rawViewer", "row" ]

  connect() {
    if (this.prettified === undefined) {
      this.unprettifyRawViewer();
    }
  }

  togglePrettified() {
    if (this.prettified) {
      this.unprettifyRawViewer()
    }
    else {
      this.prettifyRawViewer()
    }
  }

  prettifyRawViewer() {
    let code = document.createElement('code');
    code.classList.add("json");
    code.textContent = this.prettifiedContent();
    hljs.highlightElement(code);

    this.rawViewerTarget.textContent = "";
    this.rawViewerTarget.append(code);

    this.rawViewerTarget.classList.remove("text-wrap");
    this.prettified = true;
  }

  unprettifyRawViewer() {
    let code = document.createElement('code');
    code.classList.add("json", "text-wrap");
    code.textContent = this.unprettifiedContent();
    hljs.highlightElement(code);

    this.rawViewerTarget.textContent = "";
    this.rawViewerTarget.append(code);

    // this.rawViewerTarget.textContent = this.unprettifiedContent();
    this.rawViewerTarget.classList.add("text-wrap");
    this.prettified = false;
  }

  prettifiedContent() {
    const currentContent = this.rawViewerTarget.textContent;
    return JSON.stringify(JSON.parse(currentContent), null, 2);
  }

  unprettifiedContent() {
    const currentContent = this.rawViewerTarget.textContent;
    return JSON.stringify(JSON.parse(currentContent), null, 0);
  }

  copyRaw() {
    copyTextToClipboard(this.rawViewerTarget.textContent);
  }
}
