import { Controller } from "stimulus"

// TODO: when selectedRow !== undefined, allow user to navigate using arrow keys
// TODO: add .has-validation / .in-invalid when regexp is invalid
// TODO: add copy/export visible (filter out things then export what's visible)

export default class extends Controller {
  static targets = [ "row", "filter", "filterAttribute", "filterPredicate" ]

  connect() {
    if (this.initialized !== true) {
      this.initialized = true;
      this.selectedRow = undefined;
      this.collapsedTreePaths = [];

      this.filteredOutTreePaths = [];
      this.filterPredicate = "contains"
      this.filterAttribute = "path"
      this.updateFilter();
    }
  }

  clearFilter() {
    this.filterTarget.value = "";
    this.filterTarget.focus();
    this.updateFilter();
  }

  setFilterAttribute(el) {
    this.filterAttribute = el.target.dataset.filterAttribute;
    this.filterAttributeTarget.textContent = el.target.textContent;
    this.updateFilter();
  }

  setFilterPredicate(el) {
    this.filterPredicate = el.target.dataset.filterPredicate;
    this.filterPredicateTarget.textContent = el.target.textContent;
    this.updateFilter();
  }

  updateFilter() {
    this.filteredOutTreePaths = [];

    if (this.filterTarget.value.length == 0) {
      this.updateDisplay();
      return;
    }

    // Set attribute
    let attribute = null;
    switch(this.filterAttribute) {
    case 'path':
      attribute = (row) => row.dataset.treePath;
      break;
    case 'key':
      attribute = (row) => row.dataset.treePath.split(".").pop();
      break;
    case 'value':
      attribute = (row) => {
	if (row.dataset.hasChildren == "1") {
	  return "";
	}
	else {
	  return row.querySelector('td:nth-child(2)').textContent.trim();
	}
      }
      break;
    default:
      throw `Error: unknown attribute (${this.filterAttribute})`
    }

    // Set token & predicate
    let token = null;
    let predicate = null;
    switch(this.filterPredicate) {
    case 'contains':
      predicate = (attribute, token) => attribute.includes(token);
      token = this.filterTarget.value;
      break;
    case 'equals':
      predicate = (attribute, token) => attribute === token;
      token = this.filterTarget.value;
      break;
    case 'starts-with':
      predicate = (attribute, token) => attribute.startsWith(token);
      token = this.filterTarget.value;
      break;
    case 'regexp':
      try {
	token = new RegExp(this.filterTarget.value);
	predicate = (attribute, regexp) => regexp.test(attribute);
      } catch (error) {
	console.log("Error: invalid regexp");
	console.log(error);
	// TODO: has-validation / in-invalid
	predicate = (attribute, token) => true
      }
      break;
    default:
      throw `Error: unknown predicate (${this.filterPredicate})`;
    }

    let parents = new Set();
    this.rowTargets.forEach(row => {
      const treePath = row.dataset.treePath;
      if (predicate(attribute(row), token)) {
	let parts = treePath.split(".");
	do {
	  parents.add(parts.join("."));
	  parts.pop();
	} while (parts.length > 0);
      }
      else {
	this.filteredOutTreePaths.push(treePath)
      }
    });
    this.filteredOutTreePaths = this.filteredOutTreePaths.filter(v => !parents.has(v))

    this.updateDisplay();
  }

  select(element) {
    const keyElement = element.target;
    const rowElement = keyElement.closest("tr");

    if (this.selectedRow !== undefined) {
      this.selectedRow.classList.remove("tree-selected");
    }
    this.selectedRow = rowElement;
    this.selectedRow.classList.add("tree-selected");
  }

  expandAll() {
    this.collapsedTreePaths = [];
    this.updateDisplay();
  }

  collapseAll() {
    this.collapsedTreePaths = [];
    this.rowTargets.forEach(row => this.collapsedTreePaths.push(row.dataset.treePath));

    if (this.selectedRow !== undefined) {
      this.selectedRow.classList.remove("tree-selected");
    }
    this.selectedRow = this.rowTarget;
    this.selectedRow.classList.add("tree-selected");

    this.updateDisplay();
  }

  toggleCollapsed(element) {
    if (this.isSelectedKeyCollapsed()) {
      this.expandSelectedKey();
    }
    else {
      this.collapseSelectedKey();
    }
    this.updateDisplay();
  }

  isCollapsed(treePath) {
    return this.collapsedTreePaths.indexOf(treePath) > -1;
  }

  isUnderCollapsed(treePath) {
    for (let i = 0; i < this.collapsedTreePaths.length; i++) {
      const prefix = `${this.collapsedTreePaths[i]}.`;

      if (treePath.startsWith(prefix))
	return true;
    }
    return false;
  }

  isFilteredOut(treePath) {
    return this.filteredOutTreePaths.indexOf(treePath) > -1;
  }

  isHidden(treePath) {
    return (this.isFilteredOut(treePath) || this.isUnderCollapsed(treePath));
  }

  updateDisplay() {
    const collapsedTreePaths = this.collapsedTreePaths;

    this.rowTargets.forEach(row => {
      const treePath = row.dataset.treePath;

      if (this.isHidden(treePath)) {
	row.setAttribute("hidden", true);
      }
      else {
	row.removeAttribute("hidden");
	if (this.isCollapsed(treePath)) {
	  row.classList.remove("expanded");
	  row.classList.add("collapsed");
	}
	else {
	  row.classList.remove("collapsed");
	  row.classList.add("expanded");
	}
      }
    });
  }

  isSelectedKeyCollapsed() {
    return this.isCollapsed(this.selectedRow.dataset.treePath);
  }

  expandSelectedKey() {
    const treePath = this.selectedRow.dataset.treePath;

    this.collapsedTreePaths = this.collapsedTreePaths.filter(value => value !== treePath);
  }

  collapseSelectedKey() {
    const treePath = this.selectedRow.dataset.treePath;

    this.collapsedTreePaths.push(treePath);
  }
}
