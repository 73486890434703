/* When a button gets clicked, it does not loose focus by itself and stays outlined.
   This workaround fixes the issue. */
document.addEventListener("click", function(el) {
  let iterator = el.target;
  while (iterator !== undefined && iterator !== null && iterator !== document) {
    if (iterator.tagName === "BUTTON") {
      if (iterator.classList.contains("btn")) {
	iterator.blur();
      }
      return true;
    }
    iterator = iterator.parentElement;
  }
  return true;
});
