import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "link", "title" ]

  fetchLink(event) {
    let link = this.linkTarget.value
    let formData = new FormData();

    if (link.length < 1) {
      console.log('empty....K')
      console.log(this.linkTarget)
      return false;
    }
    else console.log("not empty, pursuing...");

    formData.append('bookmark[link]', link)

    Rails.ajax({
      type: "POST",
      url: "/bookmarks/fetch_link",
      dataType: "json",
      data: formData,
      success: (response) => {
	this.titleTarget.value = response.title
      },
      error: (response) => {
	console.log(response)
	this.titleTarget.innerText = "(An error occured)"
      }
    })
  }
}
