import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  // connect() {
  //   console.log("conn ect");
  // }

  submit(event) {
    event.preventDefault();

    let keywords = this.inputTarget.value

    // Source: https://stackoverflow.com/a/4032642
    keywords = keywords.match(/\w+|"(?:\\"|[^"])+"/g);
    // Source: https://stackoverflow.com/a/4032642

    let newInputName = this.inputTarget.name + "[]"

    keywords.forEach(keyword => {
      let newInput = document.createElement("input")
      newInput.setAttribute('type', 'hidden')
      newInput.setAttribute('name', newInputName)
      newInput.setAttribute('value', keyword)
      this.inputTarget.parentElement.appendChild(newInput)
    })
    this.inputTarget.removeAttribute('name')

    event.target.form.submit()
  }
}
