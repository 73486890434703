import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  setCurrentDateTime(event) {
    let selectElements = Array.from(this.element.getElementsByTagName('select'))
    let currentDate = new Date()
    let values = [
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes() - (currentDate.getMinutes() % 5),
    ]

    for (let i = 0; i < values.length; i++) {
      let input = selectElements.find(element => element.id.endsWith(`_${i + 1}i`))
      console.log(values[i])
      input.value = values[i]
    }
  }
}
