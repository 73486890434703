import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['privacyPolicyCheckbox', 'submitButton']

  connect() {
    this.updateSubmitEnabling()
  }

  updateSubmitEnabling() {
    let checkbox = this.privacyPolicyCheckboxTarget

    if (checkbox.checked) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove("btn-outline-success")
      this.submitButtonTarget.classList.add("btn-success")
    }
    else {
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.remove("btn-success")
      this.submitButtonTarget.classList.add("btn-outline-success")
    }
  }
}
