import { Controller } from "stimulus"
import Cookies from "js-cookie"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "choice" ]

  connect() {
    this.choiceTargets.forEach(choice => {
      const name = choice.dataset.name
      choice.checked = (Cookies.get(name) === "true")
    })
  }

  saveChoice() {
    this.choiceTargets.forEach(choice => {
      const name = choice.dataset.name
      if (choice.checked) {
        Cookies.set(name, "true", { expires: 14 }) // expiration in days
      }
      else {
        Cookies.set(name, "false")
      }
    })

    /*
    ** First we make an AJAX call for the backend to apply the new choices.
    ** Then we redirect the user either to a predefined page, or to the root page.
    */
    Rails.ajax({
      type: "GET",
      url: window.location,
      dataType: "json",
      success: (response) => {
	// https://stackoverflow.com/a/901144/853065
	const params = new Proxy(new URLSearchParams(window.location.search), {
	  get: (searchParams, prop) => searchParams.get(prop),
	})

	if (params.redirect_to &&
	    (!params.required_cookie ^ Cookies.get(params.required_cookie) === "true")) {
	  window.location = params.redirect_to
	} else {
	  window.location = "/"
	}
      }
    })
  }

  acceptAll() {
    this.choiceTargets.forEach(choice => choice.checked = true)
    this.saveChoice()
  }

  refuseAll() {
    this.choiceTargets.forEach(choice => choice.checked = false)
    this.saveChoice()
  }
}
