document.addEventListener("turbolinks:load", function() {
  const links = document.getElementsByClassName("link-to-anchor")

  if (links) {
    for (let i = 0; i < links.length; i++) {
      const link = links[i]
      link.addEventListener("click", (event) => {
	event.preventDefault()
	document.location.hash = link.attributes["href"].value;
      });
    }
  }
});
