import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'input' ]

  insertRubyBlock() {
    this.insertCodeBlock("ruby")
  }

  insertHtmlBlock() {
    this.insertCodeBlock("html")
  }

  insertJavascriptBlock() {
    this.insertCodeBlock("javascript")
  }

  insertTextBlock() {
    this.insertCodeBlock("text")
  }

  insertCodeBlock(lang) {
    this.insertAtCaret("```" + lang + "\n", "\n```\n")
  }

  insertLink() {
    this.insertAtCaret("[", "](url)")
  }

  insertInlineCode() {
    this.insertAtCaret("`", "`")
  }

  insertAtCaret(beforeText, afterText) {
    const element = this.inputTarget
    const [start, end] = [element.selectionStart, element.selectionEnd];
    element.setRangeText(afterText, end, end, 'select');
    element.setRangeText(beforeText, start, start, 'select');
  }
}
