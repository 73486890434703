// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "controllers"
import "bootstrap"
import "./bootstrap_button_focus_bugfix"
import "./prevent_page_reload_on_anchor_links"
import feather from "feather-icons"
// import { hljsHighlight } from "./highlight"

Rails.start()
Turbolinks.start()

document.addEventListener("turbolinks:load", function() {
  feather.replace()
  // hljsHighlight()

  // document.querySelectorAll('a.anchor').forEach((element) => {
  //   element.addEventListener('click', (event) => {
  //     event.preventDefault();
  //     element.scrollIntoView();
  //   });
  // });
});
