import hljs from 'highlight.js/lib/core'

import plaintext from 'highlight.js/lib/languages/plaintext'
hljs.registerLanguage('plaintext', plaintext);
hljs.registerAliases('text', { languageName: 'plaintext' });
hljs.registerAliases('txt', { languageName: 'plaintext' });

import c from 'highlight.js/lib/languages/c'
hljs.registerLanguage('c', c);

import cpp from 'highlight.js/lib/languages/cpp'
hljs.registerLanguage('cpp', cpp);

import ruby from 'highlight.js/lib/languages/ruby'
hljs.registerLanguage('ruby', ruby);
hljs.registerAliases('rb', { languageName: 'ruby' });

import xml from 'highlight.js/lib/languages/xml'
hljs.registerLanguage('xml', xml);
hljs.registerAliases('html', { languageName: 'xml' });

import css from 'highlight.js/lib/languages/css'
hljs.registerLanguage('css', css);

import scss from 'highlight.js/lib/languages/scss'
hljs.registerLanguage('scss', scss);

import erb from 'highlight.js/lib/languages/erb'
hljs.registerLanguage('erb', erb);

import ini from 'highlight.js/lib/languages/ini'
hljs.registerLanguage('ini', ini);
hljs.registerAliases('conf', { languageName: 'ini' });

import markdown from 'highlight.js/lib/languages/markdown'
hljs.registerLanguage('markdown', markdown);
hljs.registerAliases('md', { languageName: 'markdown' });

import bash from 'highlight.js/lib/languages/bash'
hljs.registerLanguage('bash', bash);
hljs.registerAliases(['sh', 'shell'], { languageName: 'bash' });

import javascript from 'highlight.js/lib/languages/javascript'
hljs.registerLanguage('javascript', javascript);
hljs.registerAliases('js', { languageName: 'javascript' });

import json from 'highlight.js/lib/languages/json'
hljs.registerLanguage('json', json);

import nginx from 'highlight.js/lib/languages/nginx'
hljs.registerLanguage('nginx', nginx);

import sql from 'highlight.js/lib/languages/sql'
hljs.registerLanguage('sql', sql);

import yaml from 'highlight.js/lib/languages/yaml'
hljs.registerLanguage('yaml', yaml);
hljs.registerAliases('yml', { languageName: 'yaml' });

// Disable language auto-detection in favor of explicit language declaration in markdown document.
hljs.configure({languages: []})

const hljsHighlight = (root=undefined) => {
  if (root === undefined) {
    root = document
  }
  // Reminder: :not(.hljs) => not already highlighted
  root.querySelectorAll('pre[lang] code:not(.hljs)').forEach((element) => {
    const parentElement = element.parentElement
    const lang = parentElement.getAttribute('lang')

    element.classList.add(lang || "plaintext")

    hljs.highlightElement(element)
  })
}

export { hljs, hljsHighlight };
